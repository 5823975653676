<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="mb-4 text-center">
        <a href="/">
          <img src="../assets/images/logo/logo_dark.png" class="img-fluid" />
        </a>
      </div>

      <div class="card bitcoin-wallet">
        <div class="card-block">
          <h5 class="mb-2">Page introubable</h5>
          <h2 class="mb-2 f-w-300 text-warning">404</h2>
          <i class="fas fa-minus-circle f-60 text-warning"></i>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body text-center">
          Retourner à la <br />page de
          <router-link :to="{ name: 'Login' }">Connexion</router-link> |
          <a href="/">Accueil</a>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/layouts/Footer";
export default {
  name: "PageNotFound",
  components: { Footer }
};
</script>

<style scoped></style>
